/* You can add global styles to this file, and also import other style files */
$containerBackgroundcolor: #f7f7f7;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

.contentClass {
  width: 100%;
  background-color: $containerBackgroundcolor;
  border-top-style: solid;
  border-top-color: #dddddd;
}

.gridgroupbyStyle {
  .dx-icon-edit::before {
    content: url(assets/images/edit.svg);
  }

  .dx-link{
    width : 25px !important;
    height:  20px !important;
  }

  

  .dx-icon-trash::before {
    content: url(assets/images/Delete.svg);
  }
  .dx-icon-save::before {
    content: url(assets/images/save.svg) !important;
  }

  .dx-icon-revert::before {
    content: url(assets/images/revert.svg) !important;
  }
}

.verminMeasuresTable {
  .dx-checkbox-icon::before{
    color: #006b0c !important;
  } 
  .dx-checkbox-icon{
    border-color: lightgray;

  }
}

.monitoringTableStyle {
  .dx-icon-edit::before {
    content: url(assets/images/check02.svg);
  }
  .dx-icon-save::before {
    content: url(assets/images/save.svg) !important;
  }

  .dx-icon-revert::before {
    content: url(assets/images/revert.svg) !important;
  }

  .dx-datagrid-group-closed::before{
    content: url(assets/images/history.svg)!important;
    width: 20px;
  }

  .dx-icon-trash::before {
    content: url(assets/images/Delete.svg);
  }

  .dx-datagrid-group-opened::before{
    content: url(assets/images/history.svg)!important;
    width: 20px;
  }

  .dx-datagrid-header-panel {
    display: none !important;
  }

  .dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused){
    color: black !important;
    background-color: white !important;
  }

  .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
  .dx-datagrid-rowsview
    .dx-data-row
    .dx-cell-modified.dx-datagrid-invalid::after {
    border-color: white !important;
  }
}

.windowTitleBackground {
  .dx-popup-title {
    background-color: #006b0c;
    color: white;
  }
  .dx-icon-close {
    color: white !important;
  }
}

.dx-scrollview-content{
  height: inherit;
}

.dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content{
  min-height: 100% !important;
}


::ng-deep .tagBoxStyle {
  .dx-tag-content {
    border-radius: 10px;
  }
  .dx-tag-remove-button {
    visibility: hidden;
  }
}

.orderDetailStyle {
  border-radius: 15px !important;
  margin-left: 5px;
  margin-right: 5px;
}
